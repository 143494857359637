body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* "Poppins", "Roboto", "Helvetica", "Arial", sans-serif */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
 * materialize css and material ui interoperability workaround
 * see: https://github.com/mui-org/material-ui/issues/4472
 * We rely on materialize css for parallax effect.
 */

input:focus:not([read-only]) {
  border-bottom: none !important;
  box-shadow: none !important;
}

input:not([read-only]) {
  border-bottom: none !important;
  box-shadow: none !important;
}

input {
  height: auto !important;
}

input[type='text'].MuiOutlinedInput-input,
input[type='date'].MuiOutlinedInput-input {
  margin: 0 !important;
  padding: 18.5px 14px !important;
}

input[type='text'].MuiFilledInput-input,
input[type='date'].MuiFilledInput-input {
  margin: 0 !important;
  padding: 27px 12px 10px !important;
}

@media (min-width: 600px) {
  .MuiContainer-root {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .headerAction {
    display: none;
  }
}
@media (min-width: 750px) {
  .headerAction {
    display: block;
  }
}
@media (min-width: 680px) {
  .MuiContainer-root {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
}
@media (min-width: 780px) {
  .MuiContainer-root {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

.MuiButton-root {
  font-weight: 600 !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #dddddd !important;
}

.MuiButton-containedSizeLarge {
  padding: 12px 22px 10px 22px !important;
}

.MuiButton-contained {
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 15%) !important;
}

.MuiButton-contained:hover {
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 25%) !important;
}

.MuiButton-containedSizeLarge {
  border-radius: 10px !important;
}
.MuiButton-contained {
  border-radius: 10px !important;
}

.MuiSelect-select:focus {
  background-color: rgba(0, 0, 0, 0) !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0px !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 65px !important;
}

/* hack for Safari browser, iphone, etc. */
.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
  height: 19px !important;
}

.MuiInputBase-root[class*='MuiOutlinedInput-root'] .MuiInputBase-input {
  height: 19px !important;
}

.MuiPopover-root.hamburger .MuiPopover-paper {
  max-width: 100%;
  box-shadow: 0px 4px 6px 2px rgb(0 0 0 / 14%);
  left: 0 !important;
  top: 9px !important;
}
